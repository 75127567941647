@tailwind base;
@tailwind components;
@tailwind utilities;
html {
  height: 100%;
  overflow: auto;
  color: #1d314c;
}

body {
  height: 100%;
}
svg {
  max-width: 100%;
  height: auto;
}

.main-app > div {
  max-width: 1920px;
  width: 100%;
}

.bg-main {
  background: url('./resources/images/background.png') no-repeat center
    center/100% 100% fixed;
}

.gradient-text {
  background: linear-gradient(90deg, #395df2, #2391f6 52.6%, #b072ff);
  -webkit-background-clip: text;
  background-clip: text;
}

.navLink:hover {
  color: #348ef8;
}

.btn-secondary:disabled {
  cursor: not-allowed;
}

.top-banner {
  background-image: url('./resources/images/bounce-banner.png');
  background-repeat: no-repeat;
  background-size: 100% 100%;
  min-height: 49px;
  color: #fff;
  font-weight: 500;
  font-size: 16px;
  text-align: center;
}

@media (max-width: 600px) {
  .top-banner {
    background-size: auto 100%;
    font-size: 14px;
    padding: 12px;
    min-height: unset;
  }
  .top-banner svg {
    width: 32px;
  }
}

@media (prefers-reduced-motion: no-preference) {
  .loading-spin {
    animation: loading-spin infinite 5s linear;
  }
}

.btn-secondary {
  position: relative;
  border-radius: 8px;
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
  text-align: center;
  background: linear-gradient(90deg, #395df2 0%, #2391f6 52.6%, #b072ff 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.btn-secondary:before {
  background: linear-gradient(90deg, #395df2 0%, #2391f6 52.6%, #b072ff 100%);
  content: '';
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  z-index: -1;
  border-radius: 8px;
  transition: all 0.5s;
}
.btn-secondary:after {
  background: white;
  content: '';
  display: block;
  position: absolute;
  left: 1px;
  top: 1px;
  bottom: 1px;
  right: 1px;
  z-index: -1;
  border-radius: 7px;
  transition: all 0.5s;
}

.dark {
  --color-bg-primary: #050d32;
  --color-bg-secondary: #f4f7ff;
  --color-bg-thirdry: #23284e;
  --color-bg-fourth: #11193c;
  --color-bg-fifth: #12193c;
  --color-bg-sixth: #1b65f6;
  --color-bg-overlay: #101b30;
  --color-bg-table-row: #1b1f3e;
  --color-bg-success: #24a148;
  --color-bg-danger: #fa4d56;
  --color-bg-manta-gray: #acacb4;
  --color-bg-nav: rgba(255, 255, 255, 0.9);
  --color-bg-button-primary: #0d7bc5;
  --color-bg-button-secondary: #2b49ea;
  --color-bg-button-thirdry: #00afa5;
  --color-bg-button-third-80: rgba(0, 175, 165, 0.8);
  --color-bg-button-fourth: #0e50fc;
  --color-text-primary: #ffffff;
  --color-text-secondary: #a4a3a7;
  --color-text-thirdry: #ffffff;
  --color-text-accent: #ffffff;
  --color-text-red-warning: #f30e37;
  --color-text-warning: rgba(255, 0, 0, 0.7);
  --color-text-default: #1d314c;
  --color-manta-blue: #239cff;
  --color-fill-secondary: #ffffff;
  --color-border-red: rgba(255, 0, 0, 0.6);
  --color-border-primary: #6eaed7;
  --color-border-secondary: #595f6d;
  --gradient-bg-primary: linear-gradient(to bottom right, #00182a, #000011);
  --gradient-button-primary: linear-gradient(to right, #26c8bf, #1fa2f9);
  --gradient-button-secondary: linear-gradient(
    90deg,
    #395df2,
    #2391f6 52.6%,
    #b072ff
  );
  --giantsquid-navbar-bg: linear-gradient(90deg, #eb559f 0%, #f3ab82 100%);
  --whitelist-navbar-bg: linear-gradient(90deg, #ff8049 0%, #fc51ff 100%);
  --stablecoin-navbar-bg: linear-gradient(90deg, #0058db 0%, #07bda7 100%);
}

.light {
  --color-bg-primary: #f2f2f4;
  --color-bg-secondary: #ffffff;
  --color-bg-thirdry: #f2f2f4;
  --color-bg-fourth: #61abb9;
  --color-bg-fifth: #12193c;
  --color-bg-sixth: #1b65f6;
  --color-bg-overlay: #e3e4e8;
  --color-bg-table-row: #f9fbfd;
  --color-bg-success: #24a148;
  --color-bg-danger: #fa4d56;
  --color-bg-manta-gray: #7e7e7e;
  --color-bg-nav: #040923;
  --color-bg-button-primary: #0d7bc5;
  --color-bg-button-secondary: #2b49ea;
  --color-bg-button-thirdry: #00afa5;
  --color-bg-button-third-80: rgba(0, 175, 165, 0.8);
  --color-bg-button-fourth: #0e50fc;
  --color-text-primary: #104ea1;
  --color-text-secondary: #212f41;
  --color-text-thirdry: #070707;
  --color-text-accent: #33385f;
  --color-text-red-warning: #f30e37;
  --color-text-warning: #ef9f9f;
  --color-text-default: #1d314c;
  --color-manta-blue: #239cff;
  --color-fill-secondary: #889ebc;
  --color-border-primary: #6eaed7;
  --color-border-secondary: #595f6d;
  --gradient-bg-primary: linear-gradient(to bottom right, #b0d2eb, white);
  --gradient-button-primary: linear-gradient(to bottom right, #26c8bf, #1fa2f9);
  --gradient-button-secondary: linear-gradient(
    90.17deg,
    #2b49ea -7.65%,
    #00afa5 103.69%
  );
  --giantsquid-navbar-bg: linear-gradient(90deg, #eb559f 0%, #f3ab82 100%);
  --whitelist-navbar-bg: linear-gradient(90deg, #ff8049 0%, #fc51ff 100%);
  --stablecoin-navbar-bg: linear-gradient(90deg, #0058db 0%, #07bda7 100%);
}

.custom-colors {
  --color-fill-primary: #4caaac;
  --color-fill-gray: #889ebc;
  --color-text-gray: #7e7e7e;
}

.custom-width {
  --min-width-layout: 450px;
  --min-width-table: 1024px;
  --min-width-md: 700px;
  --min-width-lg: 1200px;
}

/* .btn-animation {
  @apply transition duration-100 ease-in-out transform hover:-translate-y-1;
} */

input {
  all: unset;
}

.el-tooltip__popper.is-dark {
  border-radius: 8px;
  background: rgba(13, 16, 43, 0.7) !important;
  backdrop-filter: blur(2.5px);
}

@media (prefers-reduced-motion: no-preference) {
  .loading-spin {
    animation: loading-spin infinite 5s linear;
  }
}

@keyframes loading-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
